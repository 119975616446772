import * as React from "react"
import { Formik } from "formik"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import * as Yup from "yup"
import { stringOrUndefinedSchema } from "../../../util/yup"
import { 
	getResearchEstimate,
	updateResearchEstimate,
	handleAirtableResponse 
} from "../../../util/api/path"
import Layout from "../../../components/layouts/layout"
import ResearchEstimateForm from "../../../components/forms/researchestimate"

const ResearchEstimateSchema = Yup.object().shape({
   Email: Yup.string()
     .email('Please enter a valid email address')
     .required('Required'),
   FirstName: Yup.string()
     .required('First name is required'),
   LastName: Yup.string()
     .required('Last name is required'), 
   Objective1: Yup.string()
     .required('Objective is required'), 
   ObjectiveNotes: stringOrUndefinedSchema,
   ParticipantType: Yup.string()
     .required('Participant Type is required'), 
   Phone: stringOrUndefinedSchema, 
   PrimaryResearcher: Yup.string()
     .required('Primary Researcher is required'),     
   SecondaryResearcher: Yup.string()
   	.required('Secondary Researcher is required'),  
   Sessions: Yup.string()
     .required('Tell us how many sessions you need'),  
 })

const defaultValues = {
  Email: ``,
  FirstName: ``,
  LastName: ``,
  Objective1: ``,
  ObjectiveNotes: ``,
  ParticipantType: ``,
  Phone: ``,
  PrimaryResearcher: ``,
  SecondaryResearcher: ``,
  Sessions: ``,
}

const editableValues = (estimate) => {
	return Object.fromEntries(
  Object.entries(estimate)
    .filter(([key]) => [
      `Email`, 
      `FirstName`, 
      `LastName`, 
      `Objective1`, 
      `ObjectiveNotes`, 
      `ParticipantType`, 
      `Phone`,
      `PrimaryResearcher`,
      `SecondaryResearcher`,
      `Sessions`, 
    ].includes(key))
  )
}

const EstimatesEditPage = (props) => {
	let title = `Estimate`,
	    record = null,
	    initialValues = defaultValues
	const estimateId = props.params.id
	const response = useQuery(['estimate', estimateId], () => getResearchEstimate({ estimateId }))
	if (response.isLoading === false) {
		record = handleAirtableResponse(response)
		title = record.EstimateID
		initialValues = editableValues(record)
  }
	return (
		<Layout title={title}>
			{record ? <Formik 
				component={ResearchEstimateForm} 
				enableReinitialize={true}
      	initialValues={initialValues}
      	validationSchema={ResearchEstimateSchema}
      	onSubmit={ async (values, { setSubmitting } ) => {
        	values.id = record.id
        	values.EstimateID = record.EstimateID
        	const response = await updateResearchEstimate(values)
        	console.log(response)
        	setSubmitting(false)
      	}}
				/> : null}
		</Layout>
	)
}

export default withAuthenticationRequired(EstimatesEditPage)