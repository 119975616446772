import * as React from "react"
import { Field, ErrorMessage } from "formik"
import TextInput from "../form-elements/shared/textinput"

const ResearchEstimateForm = ({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    touched,
    values,
  }) => {
  return (
  <form 
    className="research-estimate-form space-y-6"
    onSubmit={handleSubmit}>
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Objectives</h3>
            <p className="mt-1 text-sm text-gray-500">
              What do you hope to learn from this research?
            </p>
        </div>
        <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
          <div>
            <Field
              fieldLabel="Objective" 
              name="Objective1"
              labelClassNames="block text-sm font-medium text-gray-700"
              inputClassNames="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"  
              component={TextInput} />
          </div>
          <div>
            <label htmlFor="ObjectiveNotes" className="block text-sm font-medium text-gray-700">
              Anything else you'd like us to know?
            </label>
            <div className="mt-1">
              <Field
                rows="3"
                name="ObjectiveNotes"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"  
                component="textarea" />
              <ErrorMessage name="ObjectiveNotes" />
            </div>
            <p className="mt-2 text-sm text-gray-500">We like to be really clear about what you're trying to learn.</p>
            <p className="mt-2 text-sm text-gray-500">Don't worry if you're not that clear yet. We will help you to define your Objectives.</p>
          </div>
        </div>
        </div>
      </div>

      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Core Team</h3>
            <p className="mt-1 text-sm text-gray-500">What level of research expertise are you looking for in the core team?</p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="PrimaryResearcher" className="block text-sm font-medium text-gray-700">
                  Primary Researcher
                </label>
                <Field
                  as="select"       
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" 
                  name="PrimaryResearcher"
                  id="PrimaryResearcher"
                  >
                  <option value="Highly experienced researcher">Highly experienced researcher</option>
                  <option value="Experienced researcher">Experienced researcher</option>
                </Field>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="SecondaryResearcher" className="block text-sm font-medium text-gray-700">
                  Secondary Researcher
                </label>
                <Field
                  as="select"       
                  className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" 
                  name="SecondaryResearcher"
                  id="SecondaryResearcher"
                  >
                  <option value="None">None</option>
                  <option value="Highly experienced researcher">Highly experienced researcher</option>
                  <option value="Experienced researcher">Experienced researcher</option>
                  <option value="Researcher">Researcher</option>
                </Field>
              </div>
              <div className="col-span-6">
                <p className="mt-2 text-sm text-gray-500">Working with amazing researchers is the key to great results!</p>
                <p className="mt-2 text-sm text-gray-500">Don't worry, we will help you get this right.</p>
                <p className="mt-2 text-sm text-gray-500">The default is for a strong Lead Researcher. If you need more than 12 Sessions, you will get better outcomes by adding a Support Researcher as well.</p>
                <p className="mt-2 text-sm text-gray-500">Our Researchers set their own rates based on skill and experience. Your Estimate will be based on average rates, and we'll provide you an accurate Quote once you've selected Researchers to work with.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Sessions and Participants</h3>
            <p className="mt-1 text-sm text-gray-500">Who do you need to research with?</p>
          </div>
          <div className="mt-5 space-y-6 md:col-span-2 md:mt-0">
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="ParticipantType" className="block text-sm font-medium text-gray-700">
                ParticipantType
              </label>
              <Field
                as="select"       
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" 
                name="ParticipantType"
                id="ParticipantType"
                >
                <option value="Consumers" label="Consumers">Consumers</option>
                <option value="Professionals" label="Professionals">Professionals</option>
                <option value="Hard to reach" label="Hard to reach">Hard to reach</option>
              </Field>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label htmlFor="Sessions" className="block text-sm font-medium text-gray-700">
                How many Sessions?
              </label>
              <Field
                as="select"       
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" 
                name="Sessions"
                id="Sessions"
                >
                <option value="5" label="Extra small (5 Sessions)">Extra small (5 Sessions)</option>
                <option value="8" label="Small (8 Sessions)">Small (8 Sessions)</option>
                <option value="12" label="Standard (12 Sessions)">Standard (12 Sessions)</option>
                <option value="24" label="Large (24 Sessions)">Large (24 Sessions)</option>
                <option value="36" label="Extra large (36 Sessions)">Extra large (36 Sessions)</option>
                <option value="48" label="Double extra large (48 Sessions)">Double extra large (48 Sessions)</option>
              </Field>
            </div>
            <div className="col-span-6">
              <p className="mt-2 text-sm text-gray-500">Our Estimate is based on the number of research Sessions we undertake with participants.</p>
              <p className="mt-2 text-sm text-gray-500">The number, duration and type of participant of research Sessions has a big impact on the cost and quality of your learnings.</p>
              <p className="mt-2 text-sm text-gray-500">12 Sessions will give you a good introduction to a new topic, however even a small number of Sessions can help a team move forward.</p>
              <p className="mt-2 text-sm text-gray-500">We've assumed standard 60m Sessions for now, we'll refine this with a Research Briefing before providing you with an accurate Quote.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Details</h3>
            <p className="mt-1 text-sm text-gray-500">Where should we send your Estimate?</p>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <Field
                  fieldLabel="First name" 
                  name="FirstName"
                  labelClassNames="block text-sm font-medium text-gray-700"
                  inputClassNames="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"  
                  component={TextInput} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Field
                  fieldLabel="Last name" 
                  name="LastName"
                  labelClassNames="block text-sm font-medium text-gray-700"
                  inputClassNames="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"  
                  component={TextInput} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Field
                  fieldLabel="Email address" 
                  name="Email"
                  labelClassNames="block text-sm font-medium text-gray-700"
                  inputClassNames="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"  
                  component={TextInput} />
              </div>
              <div className="col-span-6 sm:col-span-3">
                <Field
                  fieldLabel="Phone number" 
                  name="Phone"
                  labelClassNames="block text-sm font-medium text-gray-700"
                  inputClassNames="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"  
                  component={TextInput} />
              </div>
            </div>
            <div className="col-span-6">
              <p className="mt-2 text-sm text-gray-500">We'll be in touch shortly to share your Estimate.</p>
              <p className="mt-2 text-sm text-gray-500">After refining your Brief, we will give you an accurate Quote.</p>
              <p className="mt-2 text-sm text-gray-500">Your project can be designed and scheduled within 2 days.</p> 
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Save
        </button>
      </div>
  </form>
)}

export default ResearchEstimateForm