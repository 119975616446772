import * as React from "react"

const TextInput = ({ 
	field, 
	form: { touched, errors, values },
	fieldLabel,
	labelClassNames,
	inputClassNames,
}) => {
	const fieldName = field.name
	return (
		<>
			<label htmlFor={fieldName} className={labelClassNames}>
        {fieldLabel}
      </label>
			<input
		    type="text"
		    className={inputClassNames}
		    {...field}
		  />
		  {errors[fieldName] && touched[fieldName] ? (<div>{errors[fieldName]}</div>) : null}
	  </>	
	)
}

export default TextInput