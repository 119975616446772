const yup = require(`yup`)

const stringOrUndefinedSchema = yup.lazy((value) => {
  switch (typeof value) {
    case 'undefined':
      return yup.string().default('')
    case 'string':
      return yup.string()
    default:
      throw new yup.ValidationError("Please enter a string or number")
  }
})
module.exports = {
  stringOrUndefinedSchema,
}